@use 'sass:color';

.btn {
	&-primary:not(.nav-link) {
		border-color: gray;
		background-color: gray;
		color: white;

		&:hover {
			border-color: color.adjust($color: gray, $lightness: -10%);
			background-color: color.adjust($color: gray, $lightness: -10%);
			color: white;
		}
	}
}
