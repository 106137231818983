form {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	gap: 1rem;

	.group {
		display: grid;
		gap: 1rem;
		grid-template-columns: 1fr 3fr;
	}

	input, select, label {
		padding: 0.5rem;
		line-height: 1.5 !important;
	}

	input, select {
		border: 1px solid gray;
		border-radius: 0.25rem;
	}

	label {
		&.required {
			&:after {
				color: red;
				content: "*";
			}
		}
	}

	input[type="submit"] {
		grid-column: 1 / -1;
	}
}
